export const properties = {
  environment: "dev",
  serviceauthId: "changemajor-app-nonprod",
  domain:"https://changemajor-dev.apps.asu.edu",
  endpoint : "https://78vqvp2dm1.execute-api.us-west-2.amazonaws.com/dev/resources",
  degreesearchUrl: "https://webapp4-dev.asu.edu/programs",
  wpcareyUrl: "https://wpc-webdev.asurite.ad.asu.edu/forms/changemajor",
  wpcareyMinorsUrl: "https://devapps.wpcarey.asu.edu/apps/advising/modules/minor/index.cfm",
  wpcareyCertsUrl: "https://devapps.wpcarey.asu.edu/apps/advising/modules/certificates/index.cfm"
};
